import React, { useState, useEffect } from "react";
import Slider from "../../components/Slider/Slider";
import Categories from "../../components/Categories/Categories";
import JsonData from "../../data/data.json";
import Contact from "../../components/Contact/Contact";



const Home = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);


  return (
    <div>
      <Slider/>
      <Contact/>
      <Categories/>
    </div>
  );
};

export default Home;
