import React, { useState, useEffect }  from "react";
import { Layout } from "antd";
import { Navigation } from "./components/Navbar/navigation";
import Footer from "./components/Footer/Footer";
import AppRoutes from "./Routes";
import JsonData from "./data/data.json";

const { Header, Content } = Layout;

const App = () => {
  

  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  return (
    <Layout>
        <div className="demo-logo" />
        <Navigation />
      <Content>
        <AppRoutes />
      </Content>
      <Footer/>
    </Layout>
  );
};

export default App;
