import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

const LeftMenu = ({ mode }) => {
  return (
    <Menu mode={mode}>
        <Menu.Item key="explore">
            <Link to="/">Inicio</Link>
        </Menu.Item>
        <Menu.SubMenu title="Partners">
            <Menu.Item key="woman">
                Mujeres
            </Menu.Item>
            <Menu.Item key="man">
                Hombres
            </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu title="Vida Social"> 
            <Menu.SubMenu title="Entretenimiento">
                <Menu.Item key="dance">Baile</Menu.Item>
                <Menu.Item key="com">Comer</Menu.Item>
                <Menu.Item key="cin">Cine</Menu.Item>
                <Menu.Item key="tea">Teatro</Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu title="Deportes">
                <Menu.Item key="run">Correr</Menu.Item>
                <Menu.Item key="ped">Padel</Menu.Item>
                <Menu.Item key="ten">Tenis</Menu.Item>
                <Menu.Item key="yog">Yoga</Menu.Item>
            </Menu.SubMenu>
        </Menu.SubMenu>
        <Menu.SubMenu title="Experiencias">
            <Menu.Item key="cart">Cata de Vinos</Menu.Item>
            <Menu.Item key="expe">Experiencia Goutmeat</Menu.Item>
            <Menu.Item key="travel">Viaje en Globo</Menu.Item>
            <Menu.Item key="hourse">Montar a Caballo</Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu title="Servicios">
            <Menu.Item key="driver">Chofer</Menu.Item>
            <Menu.Item key="escol">Escolta</Menu.Item>
            <Menu.Item key="photo">Fotógrafo</Menu.Item>
            <Menu.Item key="personal">Personal Shopper</Menu.Item>
            <Menu.Item key="maqui">Maquillista</Menu.Item>
            <Menu.Item key="mes">Mesero</Menu.Item>
            <Menu.Item key="dj">Dj</Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu title="Propiedades">
            <Menu.SubMenu title="Alojamiento">
                <Menu.Item key="home">Casa</Menu.Item>
                <Menu.Item key="depa">Departamento</Menu.Item>
                <Menu.Item key="cab">Cabaña</Menu.Item>
                <Menu.Item key="loft">Loft</Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key="stu">Estudio</Menu.Item>
            <Menu.Item key="ofic">Oficina</Menu.Item>
        </Menu.SubMenu>
    </Menu>
  );
};

export default LeftMenu;