import React from "react";
import "./Profile.less";
import { 
  Button,
  Avatar,
  Card,
  DatePicker,
  Col,
  Select,
  Form,
  Input,
  message,
  Row,
  Spin } from "antd";
import { useAuthContext } from "../../context/AuthContext";
import useScreenSize from "../../hooks/useScreenSize";
import { API } from "../../constant";
import { checkStatus, parseJSON, headers } from "../../helpers";
import { useState, useEffect } from "react";
import { getToken } from "../../helpers";
import moment from "moment";
const { Meta } = Card;

const { Option } = Select;
const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { isDesktopView } = useScreenSize();
  const { user, isLoading, setUser } = useAuthContext();


  const [profile, setProfiles] = useState([]);

  const fetchProfiles = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API}/users/2?populate=address`);
      const data = await response.json();
      console.log(data);
      setProfiles(data ?? []);
    } catch (error) {
      console.error(error);
      message.error("Error while fetching profiles!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfiles();
  }, []);

  const handleProfileUpdate = async (data) => {
    const dataUser = {
      'username': data.username,
      'lastname': data.lastname,
      'secondlastname': data.secondlastname,
      'email': data.email,
      'birthday': data.birthday,
      'telephone': data.telephone,
      'sex': data.sex,
      'address': {
        'state': data.state,
        'colony': data.colony,
        'municipality': data.municipality,
        'city': data.city,
        'zipcode': data.zipcode,
        'street': data.street,
        'num_int': data.num_int,
        'num_ext': data.num_ext
      }
    }
    setLoading(true);
    try {
      const response = await fetch(`${API}/users/${user?.id}?populate=address`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          // set the auth token to the user's jwt
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(dataUser),
      });

      const responseData = await response.json();

      setUser(responseData);

      message.success("Data saved successfully!");
    } catch (error) {
      console.error(Error);
      message.error("Error While Updating the Profile!");
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return <Spin size="large" />;
  }

  return (
    <Card className="profile_page_card">
      <Form
        layout="vertical"
        initialValues={{
          username: profile.username,
          lastname: user?.lastname,
          secondlastname: user?.secondlastname,
          email: user?.email,
          birthday: moment(user?.birthday),
          telephone: user?.telephone,
          sex: user?.sex,
          state: profile.address?.state,
          city: profile.address?.city,
          municipality: profile.address?.municipality,
          colony: profile.address?.colony,
          street: profile.address?.street,
          num_int: profile.address?.num_int,
          num_ext: profile.address?.num_ext,
        }}
        onFinish={handleProfileUpdate}
      >
        <Row align="middle">
          <Col span={16} offset={4}>
            <Card
              
              cover={
                <img
                  style={{ width: 300 }}
                  alt="example"
                  src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
                />
              }
              
            >
              <Meta
                avatar={<Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />}
                title="Card title"
                description="This is the description"
              />
              <Row gutter={[16, 16]}>
          <Col span={isDesktopView ? 6 : 24} offset={isDesktopView ? 6 : 0}>
            <Form.Item
              label="Nombre"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Username is required!",
                  type: "string",
                },
              ]}
            >
              <Input placeholder="Username" bordered={false} classNames="input_border" />
            </Form.Item>

            <Form.Item
              label="Apellido Paterno"
              name="lastname"
              rules={[
                {
                  required: false,
                  type: "string",
                },
              ]}
            >
              <Input placeholder="Apellido Paterno" bordered={false} />
            </Form.Item>

            <Form.Item
              label="Apellido materno"
              name="secondlastname"
              rules={[
                {
                  required: false,
                  type: "string",
                },
              ]}
            >
              <Input placeholder="Apellido Materno" bordered={false} />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Email is required!",
                  type: "email",
                },
              ]}
            >
              <Input placeholder="Email" bordered={false} />
            </Form.Item>
            {user?.type === 'partner' ? (
              <>
            <Form.Item
              label="Fecha de nacimiento"
              name="birthday">
              <DatePicker placeholder="Fecha de necimiento" bordered={false} />
            </Form.Item>
            <Form.Item
              label="Teléfono"
              name="telephone"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input placeholder="Teléfono" bordered={false} />
            </Form.Item>
            <Form.Item
              label="Sexo"
              name="sex"
              rules={[
                {
                  required: false,
                  type: "string",
                },
              ]}
            >
              <Select placeholder="Sexo" bordered={false}>
                <Option value="female">Mujer</Option>
                <Option value="male">Hombre</Option>
              </Select>
            </Form.Item>
            
            </>
            ) : null
            }
          </Col>
          <Col span={isDesktopView ? 6 : 24} offset={isDesktopView ? 4 : 0}>
            
          {user?.type === 'cliente' ? (
              <>
            <Form.Item
              label="Fecha de nacimiento"
              name="birthday">
              <DatePicker placeholder="Fecha de necimiento" bordered={false} />
            </Form.Item>
            <Form.Item
              label="Teléfono"
              name="telephone"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input placeholder="Teléfono" bordered={false} />
            </Form.Item>
            <Form.Item
              label="Sexo"
              name="sex"
              rules={[
                {
                  required: false,
                  type: "string",
                },
              ]}
            >
              <Select placeholder="Sexo" bordered={false}>
                <Option value="female">Mujer</Option>
                <Option value="male">Hombre</Option>
              </Select>
            </Form.Item>
            
            </>
            ) : null
            }
            {user?.type === 'partner' ? (
              <>
                <Form.Item
                  label="Estado"
                  name="state"
                  rules={[
                    {
                      required: false,
                      type: "string",
                    },
                  ]}
                >
                  <Input placeholder="Estado" />
                </Form.Item>
                <Form.Item
                  label="Ciudad"
                  name="city"
                  rules={[
                    {
                      required: false,
                      type: "string",
                    },
                  ]}
                >
                  <Input placeholder="Ciudad" />
                </Form.Item>
                <Form.Item
                  label="Colonia"
                  name="colony"
                  rules={[
                    {
                      required: false,
                      type: "string",
                    },
                  ]}
                >
                  <Input placeholder="Colonia" />
                </Form.Item>
                <Form.Item
                  label="Alcaldia"
                  name="municipality"
                  rules={[
                    {
                      required: false,
                      type: "string",
                    },
                  ]}
                >
                  <Input placeholder="Alcaldia" />
                </Form.Item>
                <Form.Item
                  label="Calle"
                  name="street"
                  rules={[
                    {
                      required: false,
                      type: "string",
                    },
                  ]}
                >
                  <Input placeholder="Calle" />
                </Form.Item>
                <Form.Item
                  label="Num. Interiro"
                  name="num_int"
                  rules={[
                    {
                      required: false,
                      type: "string",
                    },
                  ]}
                >
                  <Input placeholder="Num. Interior" />
                </Form.Item>
                <Form.Item
                  label="Num. Exterior"
                  name="num_ext"
                  rules={[
                    {
                      required: false,
                      type: "string",
                    },
                  ]}
                >
                  <Input placeholder="Num. Exterior" />
                </Form.Item>
                <Form.Item
                  label="Codigo Postal"
                  name="zipcode"
                  rules={[
                    {
                      required: false,
                      type: "string",
                    },
                  ]}
                >
                  <Input placeholder="Codigo Postal" />
                </Form.Item>

              </>
            ) : null
            }
          </Col>
        </Row>
            </Card>
          </Col>
        </Row>
        
        <Button
          className="profile_save_btn"
          htmlType="submit"
          type="primary"
          size="large"
        >
          {loading ? (
            <>
              <Spin size="small" /> Saving
            </>
          ) : (
            "Save"
          )}
        </Button>
      </Form>
    </Card>
  );
};

export default Profile;
