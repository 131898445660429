import React from "react";
import { Menu, Avatar } from "antd";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UserOutlined, CodeOutlined, LogoutOutlined } from "@ant-design/icons";
import { useAuthContext } from "../../context/AuthContext";
import { removeToken } from "../../helpers";

const RightMenu = ({ mode }) => {
    const { user, setUser } = useAuthContext();
    const navigate = useNavigate();
    const handleLogout = () => {
        removeToken();
        setUser(undefined);
        navigate("/signin", { replace: true });
      };
  return (
    <Menu mode={mode}>
      <Menu.SubMenu
        title={
          <>
            <Avatar icon={<UserOutlined />} />
            <span className="username">{user ? user.username: ""}</span>
          </>
        }
      >
          {user ? (
          <>
            <Menu.Item key="about-us">
                <UserOutlined /> <Link to="/profile">Profile</Link>
            </Menu.Item>
            <Menu.Item key="log-out" onClick={handleLogout}>
                <LogoutOutlined /> Logout
            </Menu.Item>
            
          </>
        ) : (
          <>
            <Menu.Item key="sign">
                <Link to="/signin">Iniciar sesión</Link>
            </Menu.Item>
          </>
        )}
        
      </Menu.SubMenu>
    </Menu>
  );
};

export default RightMenu;